define("discourse/plugins/discourse-activity-pub/discourse/routes/activity-pub-category-followers", ["exports", "discourse/routes/discourse", "@ember/array", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-category"], function (_exports, _discourse, _array, _activityPubCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    queryParams: {
      order: {
        refreshModel: true
      },
      asc: {
        refreshModel: true
      }
    },
    model() {
      return this.modelFor("activityPub.category");
    },
    afterModel(model, transition) {
      const category = model;
      if (!this.site.activity_pub_publishing_enabled) {
        this.router.replaceWith("/404");
        return;
      }
      return _activityPubCategory.default.listActors(category.id, transition.to.queryParams, "followers").then(response => this.setProperties(response));
    },
    setupController(controller, model) {
      controller.setProperties({
        model: _activityPubCategory.default.create({
          category: model,
          actors: (0, _array.A)(this.actors || []),
          loadMoreUrl: this.meta?.load_more_url,
          total: this.meta?.total
        })
      });
    }
  });
});