define("discourse/plugins/discourse-activity-pub/discourse/routes/activity-pub-category", ["exports", "discourse/routes/discourse", "discourse/models/category", "@ember/object", "@ember/service", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _discourse, _category, _object, _service, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _discourse.default.extend((_obj = {
    router: (0, _service.inject)(),
    model(params) {
      return _category.default.findById(params.category_id);
    },
    setupController(controller, model) {
      controller.setProperties({
        model
      });
    },
    follow(actor, followActor) {
      return _activityPubActor.default.follow(actor.id, followActor.id).then(result => {
        this.controllerFor(this.router.currentRouteName).model.actors.unshiftObject(followActor);
        return result;
      });
    },
    unfollow(actor, followedActor) {
      return _activityPubActor.default.unfollow(actor.id, followedActor.id).then(result => {
        this.controllerFor(this.router.currentRouteName).model.actors.removeObject(followedActor);
        return result;
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "follow", [_object.action], Object.getOwnPropertyDescriptor(_obj, "follow"), _obj), _applyDecoratedDescriptor(_obj, "unfollow", [_object.action], Object.getOwnPropertyDescriptor(_obj, "unfollow"), _obj)), _obj));
});