define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-handle-link", ["exports", "@glimmer/component", "@ember/template-factory", "@ember/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ActivityPubActorHandleLink extends _component.default {}
  _exports.default = ActivityPubActorHandleLink;
  _class = ActivityPubActorHandleLink;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
          <a href={{this.args.actor.url}} target="_blank" rel="noopener noreferrer">
              {{this.args.actor.handle}}
          </a>
      
  */
  {
    "id": "ZuyrsphQ",
    "block": "[[[1,\"\\n        \"],[10,3],[15,6,[30,0,[\"args\",\"actor\",\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n            \"],[1,[30,0,[\"args\",\"actor\",\"handle\"]]],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-handle-link.js",
    "isStrictMode": true
  }), _class);
});