define("discourse/plugins/discourse-activity-pub/discourse/templates/activity-pub-category", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Discovery::Navigation
    @createTopic={{fn
      this.composer.openNewTopic
      (hash category=this.model preferDraft=true)
    }}
    @category={{this.model}}
  />
  
  {{#if this.site.activity_pub_publishing_enabled}}
    <ActivityPubCategoryBanner @category={{this.model}} />
  {{/if}}
  
  <ActivityPubCategoryNav @category={{this.model}} @follow={{route-action "follow"}} />
  
  {{outlet}}
  */
  {
    "id": "S/dFmN5w",
    "block": "[[[8,[39,0],null,[[\"@createTopic\",\"@category\"],[[28,[37,1],[[30,0,[\"composer\",\"openNewTopic\"]],[28,[37,2],null,[[\"category\",\"preferDraft\"],[[30,0,[\"model\"]],true]]]],null],[30,0,[\"model\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"site\",\"activity_pub_publishing_enabled\"]],[[[1,\"  \"],[8,[39,4],null,[[\"@category\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,5],null,[[\"@category\",\"@follow\"],[[30,0,[\"model\"]],[28,[37,6],[\"follow\"],null]]],null],[1,\"\\n\\n\"],[46,[28,[37,8],null,null],null,null,null]],[],false,[\"discovery/navigation\",\"fn\",\"hash\",\"if\",\"activity-pub-category-banner\",\"activity-pub-category-nav\",\"route-action\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/templates/activity-pub-category.hbs",
    "isStrictMode": false
  });
});