define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-visibility-dropdown", ["exports", "select-kit/components/combo-box", "I18n", "@ember/object", "@ember/object/computed", "@ember/runloop"], function (_exports, _comboBox, _I18n, _object, _computed, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _comboBox.default.extend({
    classNames: ["activity-pub-visibility-dropdown"],
    fullTopicPublication: (0, _computed.equal)("publicationType", "full_topic"),
    nameProperty: "label",
    content: (0, _object.computed)(function () {
      return [{
        id: "private",
        label: _I18n.default.t("discourse_activity_pub.visibility.label.private"),
        title: _I18n.default.t("discourse_activity_pub.visibility.description.private", {
          object_type: this.objectType
        })
      }, {
        id: "public",
        label: _I18n.default.t("discourse_activity_pub.visibility.label.public"),
        title: _I18n.default.t("discourse_activity_pub.visibility.description.public", {
          object_type: this.objectType
        })
      }];
    }),
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.fullTopicPublication) {
        this.set("value", "public");
      }
      (0, _runloop.scheduleOnce)("afterRender", () => {
        this.set("selectKit.options.disabled", this.fullTopicPublication);
      });
    },
    actions: {
      onChange(value) {
        this.attrs.onChange && this.attrs.onChange(value);
      }
    }
  });
});